<template>
  <div class="courseDetail">
    <div class="courseDetail-t">
      <video
        id="video"
        :src="postData.videoUrl"
        controls="controls"
        :poster="postData.imageUrl"
      ></video>
    </div>
    <div class="tab">
      <div class="title">
        <div
          :class="['title-l', { active: oIndex == 1 }]"
          @click="selectModel(1)"
        >
          课程介绍
        </div>
        <div
          :class="['title-r', { active: oIndex == 2 }]"
          @click="selectModel(2)"
        >
          课程大纲
        </div>
      </div>
    </div>
    <div class="main">
      <div class="intro" v-if="oIndex == 1">
        <div class="intro-t">
          <div class="intro-tl"><img :src="postData.imageUrl" alt="" /></div>
          <div class="intro-tr">
            <div class="title">
              {{ postData.chapterTitle }}
              {{ postData.courseTitle }}
            </div>
            <div class="info">执教：{{ postData.teacher }}</div>
            <div class="info">学校：{{ postData.school }}</div>
          </div>
          <div class="uploader" @click="uploader" v-if="postData.attachmentUrl">
            <div class="uploader-t"><img src="./img/upload.png" alt="" /></div>
            <div class="uploader-b">课件下载</div>
          </div>
        </div>
        <div class="des">
          <div class="title">课程简介</div>
          <v-h5Mtml :content="postData.courseDesc"></v-h5Mtml>
        </div>
        <div class="des">
          <div class="title">教学目标</div>
          <v-h5Mtml :content="postData.courseObjective"></v-h5Mtml>
        </div>
      </div>
      <div class="outline" v-if="oIndex == 2">
        <div class="title">选课</div>
        <div class="outline-body">
          <van-collapse v-model="activeNames">
            <van-collapse-item
              v-for="(item, index) in chapterList"
              :key="index"
              :title="item.chapterTitle"
              :name="item.dataId ? item.dataId : 'a' + index"
            >
              <div
                class="item"
                v-for="(items, indexs) in item.courseList"
                :key="indexs"
                @click="toDetail(items)"
              >
                <div class="item-l">{{ items.courseTitle }}</div>
                <div class="item-r">
                  <img
                    src="./img/play1.png"
                    v-if="items.isCompleted == 1"
                    alt=""
                  />
                  <img src="./img/play2.png" v-else alt="" />
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Collapse, CollapseItem } from "vant";

Vue.use(Collapse);
Vue.use(CollapseItem);
import { mapState } from "vuex";
import { downloadHelper } from "@/utils/utils.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { courseDetailUrl, chapterListUrl, updateCourseUrl } from "./api.js";
import { stageSts } from "./map.js";
export default {
  name: "courseDetail",
  data() {
    return {
      activeNames: [],
      oIndex: 1,
      postData: {},
      courseId: "",
      gradeType: "",
      courseType: "",
      title: "",
      chapterList: [],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.gradeType = this.$route.query.gradeType;
    this.title = this.$route.query.title;
    this.courseType = this.$route.query.courseType;
  },
  async mounted() {
    let _this = this;
    await this.getDetails();
    gloabalCount(this.courseId, 31, 1);
    this.getChapterDetails();
    var video = document.getElementById("video");
    video.addEventListener(
      "ended",
      function () {
        //监听到播放结束后，在此处可调用自己的接口
        console.log(video.currentTime, 9877);
        let time = 0;
        if (video.currentTime) {
          time = parseInt(video.currentTime);
        }
        _this.updateVideo(_this.postData.id, time);
      },
      false
    );
  },
  methods: {
    async uploader() {
      let obj = {
        src: this.postData.attachmentUrl,
        fileName: "a.pdf",
      };
      let res = await this.$axios.get(
        `/upload/20211117/c998b35f61174e6ac93dc4f6871d8f00.pdf`,
        {
          respoonseType: "blob",
        }
      );
      const fileSaver = require("file-saver");
      const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      fileSaver.saveAs(blob, "课件");
      console.log(res, 9876);
    },
    async toDetail(item) {
      this.courseId = item.id;
      await this.getDetails();
      this.getChapterDetails();
    },
    async updateVideo(courseId, currentPos) {
      let params = {
        courseId: courseId,
        currentPos: currentPos,
        duration: currentPos,
        isCompleted: 1,
        userId: this.userId,
      };
      let res = await this.$axios.post(
        `${updateCourseUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        await this.getDetails();
        this.getChapterDetails();
      }
    },
    selectModel(data) {
      this.oIndex = data;
    },
    getChapterDetails() {
      let params = {
        gradeType: this.gradeType,
        courseType: this.courseType,
        userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
      };
      this.$axios.get(`${chapterListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.chapterList = res.data.records;
          this.chapterList.forEach((ele) => {
            ele.courseList.forEach((item) => {
              if (item.id == this.postData.id) {
                ele.dataId = this.postData.id;
              }
            });
          });
          console.log(this.chapterList, "+++=");
        } else {
        }
      });
    },
    async getDetails() {
      this.activeNames = [];
      let params = {
        courseId: this.courseId,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${courseDetailUrl}`, { params });
      if (res.code === 200) {
        this.postData = res.data;
        this.activeNames.push(this.postData.id);
        console.log(this.activeNames, "____");
      }
    },

    toRoute(item) {
      this.$router.push({
        name: "activityUserList",
        query: {
          activityId: item.activityId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.courseDetail {
  box-sizing: border-box;
  min-height: 100vh;
  .courseDetail-t {
    height: 366px;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .tab {
    .title {
      display: flex;
      .title-l,
      .title-r {
        flex: 1;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        color: #323334;
        line-height: 40px;
        padding: 24px 0;
      }
      .active {
        color: #323334;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 64px;
          height: 8px;
          background: #007eff;
          border-radius: 4px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  .main {
    .intro {
      padding: 18px 32px;
      .intro-t {
        display: flex;
        border-radius: 8px;
        position: relative;
        .intro-tl {
          width: 294px;
          height: 171px;
          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }
        .intro-tr {
          margin-left: 18px;
          .title {
            font-size: 28px;
            font-weight: 700;
            color: #000000;
            line-height: 40px;
            margin-bottom: 10px;
          }
          .info {
            width: 260px;
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
          }
        }
        .uploader {
          text-align: center;
          position: absolute;
          right: 0;
          bottom: 20px;
          .uploader-t {
            width: 42px;
            height: 42px;
            margin: 0 auto 6px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .uploader-b {
            font-size: 26px;
            font-weight: 400;
            color: #c3c3c3;
            line-height: 22px;
            margin: 2px auto;
          }
        }
      }
    }
    .des {
      margin-top: 66px;
      .title {
        font-size: 36px;
        font-weight: 700;
        color: #000000;
        line-height: 50px;
        margin-bottom: 18px;
      }
      .body {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
        img {
          width: 100%;
        }
        font-size: 30px !important;
        p,
        h1,
        span {
          font-size: 30px !important;
        }
      }
    }
    .outline {
      .title {
        padding: 24px 34px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 36px;
        font-weight: 700;
        color: #000000;
        line-height: 50px;
      }
      .outline-body {
        ::v-deep .van-collapse-item__content {
          padding: 18px 50px 18px 44px;
        }
        ::v-deep .van-cell__title {
          font-weight: 700 !important;
        }
        .item {
          display: flex;
          padding: 18px 0;
          justify-content: space-between;
          align-items: center;

          .item-r {
            width: 44px;
            height: 44px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.courseDetail {
}
</style>
